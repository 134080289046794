import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Hidden,
  Zoom,
  Fade,
  Collapse,
  Button,
} from "@material-ui/core";
import CategoryIcon from "@material-ui/icons/CategoryRounded";
import ReactInfiniteImageLinkCarousel from "react-infinite-image-link-carousel";

import Footer from "../components/common/Footer";
import SectionTitle from "../components/common/SectionTitle";
import gasatulen from "../images/gasatulen1.png";
import headerImage from "../images/headerImage.svg";
import missionImage from "../images/missionImage.png";
import visionImage from "../images/visionImage.png";

import LPT from "../images/LPT.png";
import GTPoster from "../images/GTPoster.png";
import GTBuilding from "../images/GTBuilding.jpg";

import Gallery1 from "../images/project (1).jpg";
import Gallery2 from "../images/project (1).png";
import Gallery3 from "../images/project (2).jpg";
import Gallery4 from "../images/project (2).png";
import Gallery5 from "../images/project (3).png";
import Gallery6 from "../images/project (4).png";
import Gallery7 from "../images/project (5).png";
import Gallery8 from "../images/project (6).png";
import Gallery9 from "../images/project (7).png";
import Gallery10 from "../images/project (8).png";
import Gallery11 from "../images/project (9).png";
import Gallery12 from "../images/project (10).png";
import Gallery13 from "../images/project (11).png";
import Gallery14 from "../images/project (12).png";
import Gallery15 from "../images/project (13).png";
import Gallery16 from "../images/project (14).png";
import Gallery17 from "../images/project (15).png";
import Gallery18 from "../images/project (16).png";
import Gallery19 from "../images/project (17).png";
import Gallery20 from "../images/project (18).png";
import Gallery21 from "../images/project (19).png";
import Gallery22 from "../images/project (20).png";
import Gallery23 from "../images/project (21).png";
import Gallery24 from "../images/project (22).png";
import Gallery25 from "../images/project (23).png";
import Gallery26 from "../images/project (24).png";
import Gallery27 from "../images/project (25).png";

import "../index.css";
import { Helmet } from "react-helmet";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  header: {
    minHeight: "800px",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      minHeight: "610px",
    },
  },
  headerContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      flexDirection: "column-reverse",
    },
  },
  GTContainer: {
    maxWidth: "60%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      alignItems: "center",
    },
  },
  GT: {
    width: "100%",
    padding: "0 2rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      padding: "0 0.5rem",
    },
  },
  headerImage: {
    height: "70%",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "30%",
      marginBottom: "2rem",
    },
  },
  headerTitle: {
    letterSpacing: "2px",
    fontWeight: 600,
    fontSize: "3.5rem",
    color: "white",
    padding: "0 2rem",
    boxSizing: "border-box",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      letterSpacing: "1px",
      width: "100%",
      fontSize: "2rem",
      padding: "0 0.5rem",
    },
  },
  headerDesc: {
    letterSpacing: "1px",
    fontWeight: 400,
    fontSize: "1.7rem",
    color: "white",
    padding: "1.5rem 2rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: "1.3rem",
      textAlign: "left",
      padding: "1.5rem 0.7rem",
    },
  },
  headerButton: {
    fontWeight: 600,
    letterSpacing: "1.5px",
    //margin: "1.5rem 0 0",
    width: "200px",
    margin: "1.5rem 2rem",
    padding: "0.8rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      width: "160px",
      textAlign: "left",
      margin: "0.1rem 0.5rem",
      padding: "0.7rem",
      alignSelf: "flex-start",
    },
  },
  missionVisionSection: {
    //height: "80vh",
    minHeight: "700px",
    width: "100%",
    padding: "3rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
  },
  missionVisionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "90%",
    },
  },
  missionVisionContent: {
    width: "40%",
    margin: "1rem 2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1.5rem 0",
    },
  },
  missionVisionTitle: {
    letterSpacing: "1px",
    marginBottom: "2rem",
    fontSize: "4rem",
    fontWeight: "600",
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem",
    },
  },
  missionVisionDetail: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  missionVisionImageContainer: {
    maxHeight: "100%",
    maxWidth: "60%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "40%",
    },
  },
  missionVisionImage: {
    width: "100%",
    //maxHeight: "100%"
  },
  missionVisionPoint: {
    display: "flex",
    flexDirection: "row",
    margin: "0.5rem 0",
  },
  missionVisionPointContent: {
    fontSize: "1.3rem",
    //letterSpacing: "0.5px",
    color: "#666",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },

  licenseSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 2rem 3rem 2rem",
  },
  licenseList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    pointerEvents: "none",
    maxWidth: "100px",
    maxHeight: "100px",
    margin: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "115px",
      maxHeight: "115px",
      margin: "3.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "70px",
      maxHeight: "70px",
      margin: "1.7rem",
    },
  },
  slider: {
    width: "100%",
    height: "80vh",
    minHeight: "600px",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },

  sliderImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  gallery: {
    width: "100%",
    height: "80vh",
    minHeight: "400px",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },

  galleryPhoto: {
    width: "10%",
    height: "100%",
    objectFit: "cover",
  },
});

class Home extends Component {
  state = {
    sliderCount: 1,
    galleryCounter: 1,
    galleryImg: [
      { image: "../images/project (1).jpg" },
      { image: "../images/project (1).jpg" },
      { image: Gallery3 },
      { image: Gallery4 },
      { image: Gallery5 },
      { image: Gallery6 },
      { image: Gallery7 },
      { image: Gallery8 },
      { image: Gallery9 },
      { image: Gallery10 },
      { image: Gallery11 },
      { image: Gallery12 },
      { image: Gallery13 },
      { image: Gallery14 },
      { image: Gallery15 },
      { image: Gallery16 },
      { image: Gallery17 },
      { image: Gallery18 },
      { image: Gallery19 },
      { image: Gallery20 },
      { image: Gallery21 },
      { image: Gallery22 },
      { image: Gallery23 },
      { image: Gallery24 },
      { image: Gallery25 },
    ],
  };

  componentDidMount() {
    setInterval(() => {
      this.scrollAction();
    }, 3000);
    // setInterval(() => {
    //   this.scrollGalleryAction();
    // }, 2000);
  }

  viewSection = (id) => () => {
    document.getElementById(id).scrollIntoView();
  };

  handleBusinessMenu = (value) => () => {
    if (value !== this.state.businessMenu) {
      this.setState({
        businessMenu: value,
      });
    } else {
      if (window.innerWidth < 960) {
        this.setState({
          businessMenu: null,
        });
      }
    }
  };

  scrollAction = () => {
    let scrollable = document.getElementById("slider");

    this.setState(
      {
        sliderCount:
          this.state.sliderCount >= 3 ? 1 : this.state.sliderCount + 1,
      },
      () => {
        if (this.state.sliderCount === 1) {
          scrollable.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        } else {
          scrollable.scrollBy({
            top: 0,
            left: scrollable.offsetWidth,
            behavior: "smooth",
          });
        }
      }
    );
  };

  scrollGalleryAction = () => {
    let galleryScroll = document.getElementById("gallery");

    this.setState(
      {
        galleryCount:
          this.state.galleryCount >= 3 ? 1 : this.state.galleryCount + 1,
      },
      () => {
        if (this.state.galleryCount === 1) {
          galleryScroll.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        } else {
          galleryScroll.scrollBy({
            top: 0,
            left: galleryScroll.offsetWidth / 3,
            behavior: "smooth",
          });
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { galleryImg } = this.state;

    const MissionVisionContent = (props) => (
      <div className={classes.missionVisionContent}>
        <Typography
          className={classes.missionVisionTitle}
          style={{ textAlign: props.inverted ? "right" : "left" }}
        >
          {props.title}
        </Typography>
        <div className={classes.missionVisionDetail}>{props.children}</div>
      </div>
    );

    const GalleryPhoto = (props) => (
      <img src={props.img} className={classes.galleryPhoto} />
    );

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Home | Gasa Tulen</title>
        </Helmet>
        <div className={classes.slider} id="slider">
          <img src={GTBuilding} className={classes.sliderImg} />
          <img src={GTPoster} className={classes.sliderImg} />
          <img src={LPT} className={classes.sliderImg} />
        </div>

        {/* <div className={classes.gallery} id="gallery">
          {galleryImg.map((e, i) => (
            <GalleryPhoto key={i} img={e.image} />
          ))}
        </div> */}
        <div className={classes.missionVisionSection} id="dotSection">
          <div className={classes.missionVisionContainer}>
            <Hidden smDown>
              <div className={classes.missionVisionImageContainer}>
                <img
                  className={classes.missionVisionImage}
                  src={missionImage}
                  alt={missionImage}
                />
              </div>
            </Hidden>
            <MissionVisionContent title="About our company">
              <Typography className={classes.missionVisionPointContent}>
                We founded Gasa Tulen out of a pure passion for people. Our
                goals, ambitions and inspirations have come together to form a
                truly amazing company that all of us are proud to be a part of.
                Since opening in 19 September 2005, we have become masters of
                our craft. We go above and beyond to ensure you’re satisfied and
                will work with us again. Explore the rest of our site to learn
                more about what we offer, and get in touch with any questions.
              </Typography>
            </MissionVisionContent>
          </div>
        </div>

        {/* <div className={classes.licenseSection} id="curvySection">
          <SectionTitle title="Licensed by" />
          <div className={classes.licenseList}>
            <LicenseLogo img={mof} />
            <LicenseLogo img={petronas} />
            <LicenseLogo img={tnb} />
            <LicenseLogo img={cidb} />
            <LicenseLogo img={mow} />
          </div>
        </div> */}
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Home);
