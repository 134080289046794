import { GET_POSITION, GET_PLACES } from "./index";
import * as firebase from "firebase";

export const fetchData = () => dispatch => {
  firebase
    .database()
    .ref("career")
    .once("value")
    .then(snapshot => {
      dispatch({
        type: GET_POSITION,
        payload: snapshot.val()
      });
    });
  firebase
    .database()
    .ref("careerPlaces")
    .once("value")
    .then(snapshot => {
      dispatch({
        type: GET_PLACES,
        payload: snapshot.val()
      });
    });
};
