import React from "react";
import * as firebase from "firebase";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Zoom,
  Hidden,
  IconButton,
  List,
  ListItem,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MenuRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import MenuIndicator from "@material-ui/icons/LabelImportantRounded";

import gasatulen from "../../../images/gasatulen2.png";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBonslnAY3t-joPge7Ub0Ed6xssGLx7Gxc",
  authDomain: "gasatulen-9e8f3.firebaseapp.com",
  databaseURL: "https://gasatulen-9e8f3.firebaseio.com",
  projectId: "gasatulen-9e8f3",
  storageBucket: "gasatulen-9e8f3.appspot.com",
  messagingSenderId: "915287380160",
  appId: "1:915287380160:web:66f72119d98d9e29",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  navBar: {
    boxShadow: "0 1px 11px #444",
    position: "fixed",
    backgroundColor: "rgba(252, 4, 4,1)",
    width: "100%",
    height: "70px",
    zIndex: 100,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    boxSizing: "border-box",
    paddingBottom: "1rem",
    paddingLeft: 200,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 150,
      paddingBottom: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 0,
    },
  },
  navBarClear: {
    marginBottom: "70px",
  },
  navBarContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "3rem",
  },
  navBarList: {
    // width: "%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  navBarListText: {
    cursor: "pointer",
    color: "white",
    fontSize: "0.9rem",
    textAlign: "center",
    textTransform: "uppercase",
    boxSizing: "border-box",
    padding: "1rem 0.7rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      padding: "0.5rem 0.5rem 0",
    },
  },
  activeIndicator: {
    width: "30px",
    borderBottom: "2px solid white",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0.3rem",
      borderBottom: "0px",
      color: "#AD0D53",
    },
  },
  inactiveIndicator: {
    width: "30px",
    borderBottom: "2px solid rgba(0,0,0,0)",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0.3rem",
      width: "10px",
      borderBottom: "0px",
      color: "rgba(0,0,0,0)",
    },
  },
  logoContainer: {
    cursor: "pointer",
    position: "fixed",
    top: 0,
    left: 50,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      left: 30,
    },
  },
  logoTextContainer: {
    paddingLeft: "1rem",
    color: "white",
    height: "70px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  logoTextTitle: {
    fontSize: "1.3rem",
    fontWeight: 500,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  logoTextDesc: {
    fontSize: "0.7rem",
  },
  logoImgContainer: {
    boxShadow: "0 1px 11px #444",

    backgroundColor: "white",
    padding: "1rem 0.5rem 0",
    width: "70px",
    height: "70px",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  menuButton: {
    marginRight: "0.5rem",
    color: "white",
  },
  closeButton: {
    alignSelf: "flex-end",
    color: "#888",
  },
  menuIcon: {
    fontSize: "1.8rem",
  },
  closeIcon: {
    fontSize: "2.1rem",
  },
  drawer: {
    //width: "100%",
    height: "100vh",
    backgroundColor: "rgba(255,255,255,0.9)",
  },
  fullList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    padding: "0.5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "2rem",
  },
});

class PublicComponent extends React.Component {
  state = {
    drawer: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handlePage = (route) => () => {
    this.props.history.push(route);
  };

  toggleDrawer = (state) => (event) => {
    this.setState({
      drawer: state,
    });
  };

  render() {
    const { classes } = this.props;

    const NavbarList = (props) => {
      return (
        <div className={classes.navBarList}>
          <Typography
            onClick={this.handlePage(props.link)}
            className={classes.navBarListText}
            style={{
              fontWeight: window.location.pathname === props.link ? 700 : 300,
            }}
          >
            {props.title}
          </Typography>
          {window.location.pathname === props.link ? (
            <Zoom in={window.location.pathname === props.link}>
              <div className={classes.activeIndicator} />
            </Zoom>
          ) : (
            <Zoom in={window.location.pathname === props.link}>
              <div className={classes.inactiveIndicator} />
            </Zoom>
          )}
        </div>
      );
    };

    const MenuItem = (props) => (
      <ListItem
        button
        onClick={this.handlePage(props.link)}
        className={classes.menuItem}
        style={{
          fontWeight: window.location.pathname === props.link ? 700 : 300,
        }}
      >
        {window.location.pathname === props.link ? (
          <MenuIndicator className={classes.activeIndicator} />
        ) : (
          <MenuIndicator className={classes.inactiveIndicator} />
        )}
        {props.title}
      </ListItem>
    );

    const menuListSmall = (
      <div
        className={classes.fullList}
        role="presentation"
        onClick={this.toggleDrawer(false)}
        onKeyDown={this.toggleDrawer(false)}
      >
        <IconButton className={classes.closeButton}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <List>
          <MenuItem title="Home" link="/" />
          <MenuItem title="About us" link="/about-us" />
          <MenuItem title="Job seekers" link="/job-seekers" />
          <MenuItem title="Projects" link="/projects" />
          <MenuItem title="F.A.Q" link="/FAQ" />
        </List>
      </div>
    );

    return (
      <main className={classes.root}>
        <div className={classes.navBar}>
          <div className={classes.logoContainer} onClick={this.handlePage("/")}>
            <div className={classes.logoImgContainer}>
              <img className={classes.logo} src={gasatulen} alt={gasatulen} />
            </div>
            <div className={classes.logoTextContainer}>
              <Typography className={classes.logoTextTitle}>
                Gasa Tulen
              </Typography>
              <Typography className={classes.logoTextDesc}>
                Join and grow with us
              </Typography>
            </div>
          </div>
          <Hidden smDown>
            <div className={classes.navBarContent}>
              <NavbarList title="About us" link="/about-us" />
              <NavbarList title="Job seekers" link="/job-seekers" />
              <NavbarList title="Projects" link="/projects" />
              <NavbarList title="F.A.Q" link="/FAQ" />
            </div>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              onClick={this.toggleDrawer(true)}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
            <Drawer
              classes={{ paper: classes.drawer }}
              anchor="top"
              open={this.state.drawer}
              onClose={this.toggleDrawer(false)}
            >
              {menuListSmall}
            </Drawer>
          </Hidden>
        </div>
        <div className={classes.navBarClear} />
        {this.props.children}
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(PublicComponent));
