import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  divider: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
});

class CustomDivider extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.divider}>
        <div
          style={{
            borderBottom: "3px solid #4A442A",
            width: "55%",
            marginRight: "1.5px"
          }}
        />
        <div
          style={{
            borderBottom: "3px solid #C0000A",
            width: "10%",
            marginLeft: "1.5px"
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CustomDivider);
