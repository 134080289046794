import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Ubuntu",
    useNextVariants: true
  },
  palette: {
    primary: {
      light: "#444444",
      main: "#222222",
      dark: "#000000",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000000"
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "default"
        }
      }
    },
    MuiFilledInput: {
      root: {
        color: "white",
        backgroundColor: "rgba(0,0,0,0.3)",
        "&:focus-within": {
          backgroundColor: "rgba(0,0,0,0.1)"
        },
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.2)"
        }
      },
      underline: {
        "&:before": {
          borderBottom: "1.5px solid white"
        },
        "&:after": {
          borderBottom: "3px solid white"
        },
        "&:hover": {
          "&:before": {
            borderBottom: "1.5px solid white"
          }
        }
      }
    }
  }
});
