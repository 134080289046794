import React from "react";
import { Route } from "react-router-dom";

import Layout from "./component";

const PublicRoute = ({ component: Component, ...rest }) => (
  <Layout>
    <Route {...rest} render={props => <Component {...props} />} />
  </Layout>
);

export default PublicRoute;
