import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import RightArrow from "@material-ui/icons/ChevronRightRounded";

import "../../index.css";
import logo from "../../images/gasatulen1.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "#f1f1f1",
  },

  container: {
    borderTop: "1px solid #ddd",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1200px",
    width: "100%",
    height: "100%",
    padding: "1rem 2rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      padding: 0,
    },
  },
  middle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2.5rem",
      margin: "1rem 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "0 10rem",
      margin: "1rem 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  sectionTitle: {
    textTransform: "uppercase",

    color: "grey",
    letterSpacing: "1px",
    fontSize: "1rem",
    //fontWeight: 600,
    marginBottom: "0.7rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      alignSelf: "center",
    },
  },
  linkIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "1.3rem",
    color: "#888",
  },
  text: {
    fontSize: "1rem",
    letterSpacing: "0.7px",
    margin: "0 1rem",
    padding: "0.2rem 0",
    color: "#888",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  logo: {
    marginLeft: "1rem",
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0 0.5rem",
      width: "75px",
    },
  },
  snackbarContainer: {
    width: "100%",
    height: "35px",
    position: "fixed",
    bottom: 0,
    zIndex: 999,
  },
  errorSnackbar: {
    height: "100%",
    backgroundColor: "crimson",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  successSnackbar: {
    height: "100%",
    backgroundColor: "green",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  snackbarMsg: {
    color: "white",
    fontSize: "1.3rem",
  },
  MuiInputLabelRoot: {
    zIndex: 1,
    margin: "0.3rem 0.7rem",
    "&$focused": {
      color: "black",
    },
  },
});

class Footer extends Component {
  handlePage = (route) => () => {
    if (window.location.pathname !== route) {
      this.props.history.push(route);
    }
    window.scrollTo(0, 0);
  };

  handleSocial = (url) => () => {
    window.open(url);
  };

  render() {
    const { classes } = this.props;

    const LinkText = (props) => (
      <Typography
        className={classes.text}
        style={{ textTransform: "capitalize", cursor: "pointer" }}
        onClick={this.handlePage(props.page)}
      >
        {props.title}
      </Typography>
    );

    const LinkIcon = (props) =>
      props.link ? (
        <div
          onClick={this.handleSocial(props.link)}
          className={classes.linkIcon}
          style={{ cursor: "pointer" }}
        >
          <i className={props.iconName} />
          <Typography className={classes.text}>{props.title}</Typography>
        </div>
      ) : (
        <div
          onClick={this.handleSocial(props.link)}
          className={classes.linkIcon}
          style={{ cursor: "default" }}
        >
          <i className={props.iconName} />
          <Typography className={classes.text}>{props.title}</Typography>
        </div>
      );

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.left}>
            <img
              src={logo}
              alt={logo}
              className={classes.logo}
              onClick={this.handlePage("/")}
            />
            <Typography className={classes.text}>
              GASA TULEN SDN. BHD. (710188-P)
              <br />
              10727, Tingkat 1, Blok B,
              <br />
              Taman Cukai Utama Fasa 4,
              <br />
              24000 Kemaman, Terengganu,
              <br />
              Malaysia.
            </Typography>
          </div>

          <div className={classes.middle}>
            <Typography className={classes.sectionTitle}>Contact Us</Typography>
            <LinkIcon iconName="icon ion-md-call" title="+6098597203" />
            <LinkIcon iconName="icon ion-md-print" title="+6098597204" />
            <LinkIcon
              iconName="icon ion-md-mail"
              title="hr.gasatulen@gmail.com"
              link="mailto:hr.gasatulen@gmail.com"
            />
            <LinkIcon
              iconName="icon ion-logo-linkedin"
              title="LinkedIn"
              link="https://www.linkedin.com/company/gasatulen/"
            />
            <LinkIcon
              iconName="icon ion-logo-facebook"
              title="Facebook"
              link="https://www.facebook.com/gasa.tulen"
            />
          </div>
          {/* <div className={classes.right}>
            <Typography className={classes.sectionTitle}>About</Typography>
            <LinkText title="company" page="/company" />
            <LinkText title="careers" page="/careers" />
            <LinkText title="projects" page="/projects" />
  
          </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Footer));
