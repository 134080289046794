import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Footer from "../components/common/Footer";
import PageHeader from "../components/common/PageHeader";
import SectionTitle from "../components/common/SectionTitle";
import { Helmet } from "react-helmet";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  ongoingSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 0",
    maxWidth: "600px",
  },
  storyText: {
    margin: "1rem 0 2rem",
    color: "#444",
    fontSize: "1rem",
    letterSpacing: "0.5px",
    maxWidth: "1100px",
    textAlign: "justify",
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
    },
  },
  pointTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "90%",
    margin: "1rem 0",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      justifyContent: "center",
    },
  },
  pointTextContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  pointTextTitle: {
    boxSizing: "border-box",
    color: "#888",
    fontSize: "1rem",
    letterSpacing: "1px",
    fontWeight: 600,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
    },
  },
  awardSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 0",
    width: "100%",
  },
  awardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0",
  },
  awardCard: {
    backgroundColor: "#fff",
    transition: "all 0.3s",
    width: "85%",
    maxWidth: "800px",
    boxShadow: "none",
    borderBottom: "1px solid #ddd",
    "&:first-child": {
      borderRadius: 0,
    },
    "&:last-child": {
      borderRadius: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  awardCardActive: {
    backgroundColor: "#4A442A",
    transition: "all 0.3s",
    width: "85%",
    maxWidth: "800px",
    boxShadow: "none",
    borderBottom: "1px solid rgba(0,0,0,0)",
    "&:first-child": {
      borderRadius: 0,
    },
    "&:last-child": {
      borderRadius: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  awardCardHeader: {
    margin: "0.5rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  awardCardHeaderText: {
    color: "#000",
    transition: "all 0.3s",
    letterSpacing: "1.3px",
    fontWeight: 600,
    fontSize: "1.2rem",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  awardCardHeaderTextActive: {
    color: "#fff",
    transition: "all 0.3s",
    letterSpacing: "1.3px",
    fontWeight: 600,
    fontSize: "1.2rem",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  awardCardDetail: {
    margin: "0 auto",
    paddingTop: 0,
    width: "80%",
  },
  awardCardDetailText: {
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem",
    },
  },
  arrowActive: {
    color: "#fff",
    transition: "all 0.3s",
  },
  arrowInactive: {
    transition: "all 0.3s",
  },
  awardCardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
});

class Project extends Component {
  state = {
    award: null,
  };

  handleAward = (value) => () => {
    if (value !== this.state.award) {
      this.setState({
        award: value,
      });
    } else {
      this.setState({
        award: null,
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { award } = this.state;

    const PointText = (props) => (
      <div className={classes.pointTextBox}>
        <i
          className="fas fa-hammer"
          style={{
            color: "#555",
            marginRight: "1rem",
            marginTop: "0.25rem",
            fontSize: "1.1rem",
            width: "5%",
          }}
        />
        <div className={classes.pointTextContent}>
          <Typography className={classes.pointTextTitle}>
            {props.children}
          </Typography>
        </div>
      </div>
    );

    const AwardCardDetail = (props) => (
      <ExpansionPanelDetails className={classes.awardCardDetail}>
        <Typography className={classes.awardCardDetailText}>
          {props.children}
        </Typography>
      </ExpansionPanelDetails>
    );

    const ExpandIcon = (props) => (
      <ExpandMoreIcon
        className={classNames(
          award === props.value ? classes.arrowActive : classes.arrowInactive
        )}
      />
    );

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Projects | Gasa Tulen</title>
        </Helmet>
        <div className={classes.ongoingSection}>
          <Typography className={classes.storyText}>
            We are committed to serve you the best services by not just achieve
            your expectations but even more than that. Here are projects that
            believe in our philosophy as the best oil and gas service provider.
          </Typography>
          <PointText>
            PROVISION OF MANPOWER SUPPLY SERVICES FOR PETRONAS (PETRONAS
            TECHNICAL SERVICES SDN BHD)
          </PointText>
          <PointText>
            PROVISION OF MANPOWER SUPPLY SERVICES FOR PETRONAS (PETRONAS
            REFINERY & PETROCHEMICAL CORPORATION SDN BHD)
          </PointText>
          <PointText>
            PANEL CONTRACTOR FOR ADDITIONAL SERVICES FOR MECHANICAL & ELECTRICAL
            SYSTEM ALONG LEBUHRAYA PANTAI TIMUR FASA 2 (LPT2) USING SCHEDULE OF
            RATE ("SOR") (TERAS TEKNOLOGI SDN BHD)
          </PointText>
          <PointText>
            PROVISION OF PRESSURE RELIEF DEVICE MAINTENANCE SERVICES FOR
            PETRONAS CARIGALI SDN BHD ("WORKS") (PETRONAS CARIGALI SDN BHD)
          </PointText>
          <PointText>
            PROVISION OF MANPOWER SUPPLY FOR MAINTENANCE, OPERATION & HSE
            SECTION AT TANJUNG SULONG EXPORT TERMINAL (TSET), GAS PROCESSING &
            UTILITIES (GPU) (PETRONAS GAS BERHAD) AS TERM CONTRACT : 2 + 1 YEARS
          </PointText>
        </div>
        <div className={classes.awardSection}>
          <SectionTitle title="awards" />
          <Typography className={classes.storyText}>
            Since our establishment in 2005, Gasa Tulen has achieved numerous
            awards of excellence. These awards prove our non-stop dedications
            and hard works as an oil & gas service provider company.
          </Typography>
          <div className={classes.awardContent}>
            <ExpansionPanel
              className={classNames(
                award === 1 ? classes.awardCardActive : classes.awardCard
              )}
              expanded={award === 1}
              onChange={this.handleAward(1)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandIcon value={1} />}
                className={classes.awardCardHeader}
              >
                <Typography
                  className={classNames(
                    award === 1
                      ? classes.awardCardHeaderTextActive
                      : classes.awardCardHeaderText
                  )}
                >
                  Excellent performance in health, safety & environment 2013
                </Typography>
              </ExpansionPanelSummary>
              <AwardCardDetail>
                Awarded by Petronas Chemicals MTBE Sdn.Bhd. at Management of
                Contractors Performance Forum 2013
              </AwardCardDetail>
            </ExpansionPanel>
            <ExpansionPanel
              className={classNames(
                award === 2 ? classes.awardCardActive : classes.awardCard
              )}
              expanded={award === 2}
              onChange={this.handleAward(2)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandIcon value={2} />}
                className={classes.awardCardHeader}
              >
                <Typography
                  className={classNames(
                    award === 2
                      ? classes.awardCardHeaderTextActive
                      : classes.awardCardHeaderText
                  )}
                >
                  Highest Behaviour Observation Contribution
                </Typography>
              </ExpansionPanelSummary>
              <AwardCardDetail>
                Awarded by Petronas Chemicals MTBE Sdn.Bhd. at Management of
                Contractors Performance Forum 2014
              </AwardCardDetail>
            </ExpansionPanel>
            <ExpansionPanel
              className={classNames(
                award === 3 ? classes.awardCardActive : classes.awardCard
              )}
              expanded={award === 3}
              onChange={this.handleAward(3)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandIcon value={3} />}
                className={classes.awardCardHeader}
              >
                <Typography
                  className={classNames(
                    award === 3
                      ? classes.awardCardHeaderTextActive
                      : classes.awardCardHeaderText
                  )}
                >
                  SILVER Award for Contractor HSE Performance 2015
                </Typography>
              </ExpansionPanelSummary>
              <AwardCardDetail>
                Awarded by Petronas Chemicals MTBE Sdn.Bhd. at Management of
                Contractors Performance Forum 2015
              </AwardCardDetail>
            </ExpansionPanel>
            <ExpansionPanel
              className={classNames(
                award === 4 ? classes.awardCardActive : classes.awardCard
              )}
              expanded={award === 4}
              onChange={this.handleAward(4)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandIcon value={4} />}
                className={classes.awardCardHeader}
              >
                <Typography
                  className={classNames(
                    award === 4
                      ? classes.awardCardHeaderTextActive
                      : classes.awardCardHeaderText
                  )}
                >
                  Bronze Award for Highest BO Contribution 2015
                </Typography>
              </ExpansionPanelSummary>
              <AwardCardDetail>
                Awarded by Petronas Chemicals MTBE Sdn.Bhd. at Management of
                Contractors Performance Forum 2015
              </AwardCardDetail>
            </ExpansionPanel>
            <ExpansionPanel
              className={classNames(
                award === 5 ? classes.awardCardActive : classes.awardCard
              )}
              expanded={award === 5}
              onChange={this.handleAward(5)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandIcon value={5} />}
                className={classes.awardCardHeader}
              >
                <Typography
                  className={classNames(
                    award === 5
                      ? classes.awardCardHeaderTextActive
                      : classes.awardCardHeaderText
                  )}
                >
                  The most improved HSE Performance Contractor Award 2017
                </Typography>
              </ExpansionPanelSummary>
              <AwardCardDetail>
                Awarded by Petronas Chemicals MTBE Sdn.Bhd.
              </AwardCardDetail>
            </ExpansionPanel>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Project);
