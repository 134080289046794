import React, { Component } from "react";
import isEmpty from "../components/common/isEmpty";
import { fetchData } from "../store/actions/careerActions";
import CategoryIcon from "@material-ui/icons/CategoryRounded";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  MenuItem,
  Collapse,
  Hidden,
  Fade,
  Zoom,
} from "@material-ui/core";

import Footer from "../components/common/Footer";
import PageHeader from "../components/common/PageHeader";
import SectionTitle from "../components/common/SectionTitle";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  careerSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem 0",
    width: "90%",
    maxWidth: "600px",
  },
  storyText: {
    margin: "1rem 0 2rem",
    color: "#444",
    fontSize: "1rem",
    letterSpacing: "0.5px",
    width: "95%",
    maxWidth: "1100px",
    textAlign: "justify",
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "1.5rem",
  },
  pointContainer: {
    alignSelf: "center",
    maxWidth: "700px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
    },
  },
  pointTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    margin: "1rem 0",
    [theme.breakpoints.down("sm")]: {
      width: "88%",
      justifyContent: "center",
    },
  },
  pointTextContent: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  pointTextTitle: {
    boxSizing: "border-box",
    paddingRight: "1rem",
    color: "#888",
    fontSize: "1rem",
    letterSpacing: "1px",
    fontWeight: 600,
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      width: "100%",
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
    },
  },
  pointTextSub: {
    color: "#888",
    fontSize: "1rem",
    letterSpacing: "1px",
    width: "20%",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
      textAlign: "left",
    },
  },
  businessSection: {
    padding: "3rem 0",
    width: "100%",
    minHeight: "650px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "800px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "960px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
  },
  businessContent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  businessLeft: {
    padding: "1rem",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  businessRight: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 2rem",
    boxSizing: "border-box",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0.5rem 1rem",
    },
  },
  businessMenu: {
    maxWidth: "400px",
    width: "100%",
    padding: "0.3rem 1rem",
    paddingLeft: 0,
    boxSizing: "border-box",
    borderRight: "2.5px solid #ddd",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: "1rem",
      border: "1px solid #ddd",
      borderRadius: "7px",
      alignItems: "flex-start",
    },
  },
  businessMenuPoint: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    margin: "0.2rem 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  businessMenuPointText: {
    textAlign: "right",
    fontSize: "1.4rem",
    color: "#888",
    marginRight: "0.6rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginLeft: "0.6rem",
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
      color: "black",
    },
  },
});

class Career extends Component {
  state = {
    location: "",

    businessMenu: 1,
  };
  componentDidMount() {
    this.props.fetchData();
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleBusinessMenu = (value) => () => {
    if (value !== this.state.businessMenu) {
      this.setState({
        businessMenu: value,
      });
    } else {
      if (window.innerWidth < 960) {
        this.setState({
          businessMenu: null,
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { location, businessMenu } = this.state;
    const { places, position } = this.props.career;

    const PointText = (props) => (
      <div className={classes.pointTextBox}>
        <i
          className="fas fa-briefcase"
          style={{
            color: "#555",
            marginRight: "1rem",
            marginTop: "0.25rem",
            fontSize: "1.1rem",
            width: "5%",
          }}
        />
        <div className={classes.pointTextContent}>
          <Typography className={classes.pointTextTitle}>
            {props.value}
          </Typography>
          <Typography className={classes.pointTextSub}>
            {props.num} {props.num <= 1 ? "vacancy" : "vacancies"}
          </Typography>
        </div>
      </div>
    );

    const BusinessMenuPoint = (props) => (
      <div
        className={classes.businessMenuPoint}
        onClick={this.handleBusinessMenu(props.value)}
      >
        {props.current === props.value ? (
          <Zoom in={props.current === props.value}>
            <i className="fas fa-tint" style={{ marginTop: "1.5px" }} />
          </Zoom>
        ) : (
          <Zoom in={props.current !== props.value}>
            <i
              className="fas fa-tint"
              style={{
                marginTop: "1.5px",
                color: "rgba(0,0,0,0)",
              }}
            />
          </Zoom>
        )}
        {props.current === props.value ? (
          <Typography
            className={classes.businessMenuPointText}
            style={{ fontWeight: 600, color: "black" }}
          >
            {props.children}
          </Typography>
        ) : (
          <Typography className={classes.businessMenuPointText}>
            {props.children}
          </Typography>
        )}
      </div>
    );

    const BusinessPoint = (props) => (
      <div
        style={{ display: "flex", flexDirection: "row", margin: "0.5rem 0" }}
      >
        <i
          className="fas fa-caret-right"
          style={{
            marginRight: "1rem",
            marginTop: "0.1rem",
            fontSize: "1.1rem",
          }}
        />
        <Typography
          style={{
            fontSize: "1.1rem",
            textAlign: "justify",
            letterSpacing: "0.5px",
            color: "#666",
          }}
        >
          {props.children}
        </Typography>
      </div>
    );

    const BusinessDetail = (props) => {
      switch (props.current) {
        case 1: {
          return (
            <Fade in={props.current === 1} timeout={{ enter: 700, exit: 700 }}>
              <div className={classes.businessRight}>
                <BusinessPoint>Requirement specifications</BusinessPoint>
                <BusinessPoint>
                  Functional requirement and design specification
                </BusinessPoint>
                <BusinessPoint>
                  Detailed requirement and design specification
                </BusinessPoint>
                <BusinessPoint>
                  Detailed engineering and prepare work schedules
                </BusinessPoint>
                <BusinessPoint>
                  Manpower loading / mobilization schedule preparation
                </BusinessPoint>
                <BusinessPoint>
                  Progress schedule and progress monitoring preparation
                </BusinessPoint>
                <BusinessPoint>
                  Discussion and meeting with clients, other departments,
                  vendors
                </BusinessPoint>
              </div>
            </Fade>
          );
        }
        case 2: {
          return (
            <Fade in={props.current === 2} timeout={{ enter: 700, exit: 700 }}>
              <div className={classes.businessRight}>
                <BusinessPoint>
                  Excavation, sand padding and backfilling
                </BusinessPoint>
                <BusinessPoint>
                  Regular programmed maintenance activities
                </BusinessPoint>
                <BusinessPoint>Bridge crossing maintenance</BusinessPoint>
                <BusinessPoint>
                  Pipeline marker programs, pipelines pressure and leak testing
                </BusinessPoint>
                <BusinessPoint>
                  Petrol station and gas pipelines relocation
                </BusinessPoint>
              </div>
            </Fade>
          );
        }
        case 3: {
          return (
            <Fade in={props.current === 3} timeout={{ enter: 700, exit: 700 }}>
              <div className={classes.businessRight}>
                <BusinessPoint>Relief valve services</BusinessPoint>
                <BusinessPoint>Structural and fabrication works</BusinessPoint>
                <BusinessPoint>Turbo machinery and pump services</BusinessPoint>
                <BusinessPoint>
                  Remove, repair, service, and install mechanical equipment
                </BusinessPoint>
                <BusinessPoint>In-situ balancing</BusinessPoint>
                <BusinessPoint>Chemical cleaning</BusinessPoint>
                <BusinessPoint>Tank calibration</BusinessPoint>
              </div>
            </Fade>
          );
        }
        case 4: {
          return (
            <Fade in={props.current === 4} timeout={{ enter: 700, exit: 700 }}>
              <div className={classes.businessRight}>
                <BusinessPoint>
                  Preparation of Single Line Diagrams
                </BusinessPoint>
                <BusinessPoint>
                  Sizing and Selection of electrical equipment's in safe &
                  hazardous area
                </BusinessPoint>
                <BusinessPoint>
                  Co-ordination with process for preparation of P&IDs
                </BusinessPoint>
                <BusinessPoint>
                  Technical specifications for various instruments and control
                  systems
                </BusinessPoint>
                <BusinessPoint>
                  Ball Valves, ESD and Actuated Ball Valves for gas application,
                  Fire and Gas Instruments for safe and hazardous application
                </BusinessPoint>
                <BusinessPoint>Electrical cable trays routing</BusinessPoint>
                <BusinessPoint>TNB sub-station construction</BusinessPoint>
              </div>
            </Fade>
          );
        }
        case 5: {
          return (
            <Fade in={props.current === 5} timeout={{ enter: 700, exit: 700 }}>
              <div className={classes.businessRight}>
                <BusinessPoint>
                  Instrument calibration and loop checkout services
                </BusinessPoint>
                <BusinessPoint>
                  Equipment installation and installation supervision
                </BusinessPoint>
                <BusinessPoint>
                  Start Up, Pre-commissioning & commissioning site supervision
                </BusinessPoint>
                <BusinessPoint>
                  Deputation of engineers, erection & commissioning works
                </BusinessPoint>
                <BusinessPoint>
                  Process control equipment installation
                </BusinessPoint>
                <BusinessPoint>
                  Testing & calibration services skilled man power supply
                </BusinessPoint>
              </div>
            </Fade>
          );
        }
        case 6: {
          return (
            <Fade in={props.current === 6} timeout={{ enter: 700, exit: 700 }}>
              <div className={classes.businessRight}>
                <BusinessPoint>
                  Temporary facilites / building, site preparation, road, and
                  bridge
                </BusinessPoint>
                <BusinessPoint>
                  Oil & Gas pipeline civil & mechanical construction services
                </BusinessPoint>
                <BusinessPoint>
                  Steel structures, heavy foundations & equipment support works,
                  fire fighting systems, cable laying works
                </BusinessPoint>
                <BusinessPoint>Well-related installation</BusinessPoint>
                <BusinessPoint>
                  Electrical telecommunication and Mechanical, Electrical &
                  Plumbing (MEP) works
                </BusinessPoint>
                <BusinessPoint>
                  Slope protection and stabilization
                </BusinessPoint>
                <BusinessPoint>Soil improvement works</BusinessPoint>
                <BusinessPoint>Manufacturing precast product</BusinessPoint>
                <BusinessPoint>
                  Station / deport, tunnel and building construction
                </BusinessPoint>
                <BusinessPoint>
                  Site clearing earthworks - cutting, filling, compaction
                </BusinessPoint>
                <BusinessPoint>
                  Traffic management, site security, management & operation
                  batching plant
                </BusinessPoint>
                <BusinessPoint>Drainage works</BusinessPoint>
              </div>
            </Fade>
          );
        }
        default: {
          return (
            <Fade in={props.current === null} timeout={{ enter: 1000 }}>
              <div
                className={classes.businessRight}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <CategoryIcon style={{ color: "#ddd", fontSize: "3rem" }} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "1.3rem",
                    color: "#888",
                  }}
                >
                  Please select services
                </Typography>
              </div>
            </Fade>
          );
        }
      }
    };

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Job seekers | Gasa Tulen</title>
        </Helmet>

        <div className={classes.careerSection}>
          <Typography className={classes.storyText}>
            If you have any inquiries regarding the job opportunities, kindly
            email us <i>hr.gasatulen@gmail.com</i> for more information.
          </Typography>
          <TextField
            className={classes.textField}
            select
            variant="outlined"
            label="Location"
            value={location}
            onChange={this.handleChange("location")}
          >
            <MenuItem value="" disabled>
              Choose location
            </MenuItem>
            {places.map((data) => (
              <MenuItem key={data[0]} value={data[0]}>
                {data[1]}
              </MenuItem>
            ))}
          </TextField>
          {!isEmpty(location) &&
            position.map((data, i) =>
              data[2] === location ? (
                <PointText key={i} value={data[0]} num={data[1]} />
              ) : null
            )}
        </div>
        <div className={classes.businessSection} id="worldMapSection">
          <SectionTitle title="Our Services" marginTop="1rem" />
          <div className={classes.businessContent}>
            <div className={classes.businessLeft}>
              <div className={classes.businessMenu}>
                <BusinessMenuPoint value={1} current={businessMenu}>
                  Engineering
                </BusinessMenuPoint>

                <Collapse in={businessMenu === 1}>
                  <Hidden mdUp>
                    <BusinessDetail current={businessMenu} />
                  </Hidden>
                </Collapse>

                <BusinessMenuPoint value={2} current={businessMenu}>
                  Pipeline Maintenance
                </BusinessMenuPoint>
                <Collapse in={businessMenu === 2}>
                  <Hidden mdUp>
                    <BusinessDetail current={businessMenu} />
                  </Hidden>
                </Collapse>
                <BusinessMenuPoint value={3} current={businessMenu}>
                  Mechanical & Rotating
                </BusinessMenuPoint>

                <Collapse in={businessMenu === 3}>
                  <Hidden mdUp>
                    <BusinessDetail current={businessMenu} />
                  </Hidden>
                </Collapse>
                <BusinessMenuPoint value={4} current={businessMenu}>
                  Electrical & Instrument
                </BusinessMenuPoint>
                <Collapse in={businessMenu === 4}>
                  <Hidden mdUp>
                    <BusinessDetail current={businessMenu} />
                  </Hidden>
                </Collapse>
                <BusinessMenuPoint value={5} current={businessMenu}>
                  Installation & Commissioning
                </BusinessMenuPoint>
                <Collapse in={businessMenu === 5}>
                  <Hidden mdUp>
                    <BusinessDetail current={businessMenu} />
                  </Hidden>
                </Collapse>
                <BusinessMenuPoint value={6} current={businessMenu}>
                  Construction
                </BusinessMenuPoint>
                <Collapse in={businessMenu === 6}>
                  <Hidden mdUp>
                    <BusinessDetail current={businessMenu} />
                  </Hidden>
                </Collapse>
              </div>
            </div>
            <Hidden smDown>
              <BusinessDetail current={businessMenu} />
            </Hidden>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  career: state.career,
});

export default connect(mapStateToProps, { fetchData })(
  withStyles(styles)(Career)
);

{
  /* <Typography className={classes.storyText}>
            Since 2013, GASA TULEN had recorded the lagging Key Performance
            Indicator (KPI) with zero incident and also near misses case. All
            staff and manpower have given full support to ensure the safety and
            health at the workplace.
          </Typography>
          <Typography className={classes.storyText}>
            In 2017, there was no incident reported for lagging KPI. It was good
            achivement shown by all levels of organizations to ensure the zero
            incident at workplace. The achivements for 2017 are as below :
          </Typography>
          <div className={classes.pointContainer}>
            <PointText>Zero fatality</PointText>
            <PointText>Zero lost time injury</PointText>
            <PointText>Zero medical treatment cases</PointText>
            <PointText>Zero first aid cases</PointText>
            <PointText>Zero property damage/loss</PointText>
            <PointText>Zero near-misses</PointText>
          </div> */
}
