import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  header: {
    padding: "0 1rem",
    boxSizing: "border-box",
    height: "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#AD0D53",
    [theme.breakpoints.down("xs")]: {
      height: "275px"
    }
  },
  headerText: {
    fontSize: "4.5rem",
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "5px",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
      letterSpacing: "3px"
    }
  }
});

class PageHeader extends Component {
  render() {
    const { classes, title } = this.props;
    return (
      <div className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(PageHeader));
