import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Hidden } from "@material-ui/core";

import Divider from "./CustomDivider";

const styles = theme => ({
  sectionTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    marginBottom: "1.5rem"
  },
  sectionTitleText: {
    textAlign: "center",

    fontWeight: 600,
    fontSize: "2.1rem",
    letterSpacing: "1px",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem"
    }
  }
});

class SectionTitle extends Component {
  render() {
    const {
      classes,
      title,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      fontSizeL,
      fontSizeS
    } = this.props;
    return (
      <div
        className={classes.sectionTitle}
        style={{
          marginTop: marginTop,
          marginBottom: marginBottom,
          marginLeft: marginLeft,
          marginRight: marginRight
        }}
      >
        <Hidden xsDown>
          <Typography
            className={classes.sectionTitleText}
            style={{ fontSize: fontSizeL }}
          >
            {title}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography
            className={classes.sectionTitleText}
            style={{ fontSize: fontSizeS }}
          >
            {title}
          </Typography>
        </Hidden>
        <Divider />
      </div>
    );
  }
}
export default withStyles(styles)(SectionTitle);
