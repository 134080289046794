import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PublicRoute from "./components/common/layout/PublicRoute";

import Home from "./pages/Home";
import Company from "./pages/Company";
import Project from "./pages/Project";
import Career from "./pages/Career";
import FAQ from "./pages/FAQ";

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <PublicRoute exact path="/" component={Home} />
        </Switch>
        <Switch>
          <PublicRoute exact path="/about-us" component={Company} />
        </Switch>
        <Switch>
          <PublicRoute exact path="/projects" component={Project} />
        </Switch>
        <Switch>
          <PublicRoute exact path="/job-seekers" component={Career} />
        </Switch>
        <Switch>
          <PublicRoute exact path="/FAQ" component={FAQ} />
        </Switch>
        {/* <Switch>
          <PublicRoute exact path="/gallery" component={Gallery} />
        </Switch> */}
      </div>
    );
  }
}

export default App;
