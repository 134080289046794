import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Slide,
  OutlinedInput,
  InputLabel,
  FormControl,
  Button,
  InputAdornment,
} from "@material-ui/core";
import classNames from "classnames";
import * as firebase from "firebase";
import isEmpty from "../components/common/isEmpty";

import Footer from "../components/common/Footer";
import PageHeader from "../components/common/PageHeader";
import Slider from "infinite-react-carousel";

import ApplyIcon from "@material-ui/icons/Assignment";
import ReviewIcon from "@material-ui/icons/AssignmentTurnedIn";
import InviteIcon from "@material-ui/icons/MailOutlined";
import SendIcon from "@material-ui/icons/MarkunreadMailbox";

import GTOffice from "../images/GTOffice.jpg";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  applySection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1300px",
    padding: "4rem 0",
  },
  applySectionTitle: {
    fontSize: "3rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  applySectionContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    marginTop: "2rem",
  },
  stepBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "250px",
    padding: "1.5rem 0",
    border: "0.5px solid gainsboro",
    borderRadius: "7px",
    margin: "1rem",
  },
  stepBoxText: {
    textAlign: "center",
    maxWidth: "80%",
  },
  stepBoxIcon: {
    color: "dimgrey",
    fontSize: "2.7rem",
    marginBottom: "1.5rem",
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "70vh",
    marginTop: "5rem",
    [theme.breakpoints.down("xs")]: {
      minHeight: "750px",
    },
  },
  formImg: {
    width: "50%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  form: {
    backgroundColor: "#f6f6f6",
    minHeight: "600px",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formTitle: {
    margin: "1rem 0",
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: "1px",
    textAlign: "center",
    color: "black",
    fontSize: "2.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem",
    },
  },
  formDesc: {
    letterSpacing: "0.5px",
    //textAlign: "center",
    boxSizing: "border-box",
    color: "grey",
    fontSize: "1.2rem",
    width: "75%",
    maxWidth: "700px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      fontSize: "1rem",
    },
  },
  formContent: {
    margin: "1rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "75%",
    maxWidth: "700px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  formChild: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  inputLabel: {
    color: "grey",
  },
  textField: {
    width: "46%",
    margin: "1rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formButton: {
    fontWeight: 600,
    margin: "1.5rem 0 0",
    width: "100%",
    padding: "0.6rem 0",
  },
  buttonBase: {
    width: "15%",
    backgroundColor: "#C0000A",
    borderRadius: "0 7px 7px 0",
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },
});

class Gallery extends Component {
  state = {
    name: "",
    company: "",
    phone: "",
    email: "",
    inquiry: "",
    errorSnackbar: false,
    successSnackbar: false,
    status: "",
    isPhone: false,
  };
  handleChange = (value) => (e) => {
    this.setState({
      [value]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, phone, email, company, inquiry } = this.state;

    let filled =
      !isEmpty(name) &&
      !isEmpty(phone) &&
      !isEmpty(email) &&
      !isEmpty(company) &&
      !isEmpty(inquiry);
    if (filled) {
      this.sendForm();
      this.setState({
        successSnackbar: true,
        status: 200,
        message: "Thanks! Your form has been sent.",
        name: "",
        company: "",
        phone: "",
        email: "",
        inquiry: "",
      });
    } else {
      this.setState({
        message: "Oops! Please check your form again",
        errorSnackbar: true,
        status: 400,
      });
    }
    setTimeout(this.snackbarClose, 3000);
  };

  snackbarClose = () => {
    this.setState({
      errorSnackbar: false,
      successSnackbar: false,
    });
    setTimeout(() => {
      this.setState({
        status: "",
        message: "",
      });
    }, 300);
  };

  sendForm = () => {
    const { name, phone, email, company, inquiry } = this.state;

    const formRef = firebase.database().ref("inquiryForm");
    const newFormRef = formRef.push();

    newFormRef.set({
      name: name,
      phone: `+60${phone}`,
      email: email,
      company: company,
      inquiry: inquiry,
    });
  };
  render() {
    const { classes } = this.props;
    const {
      name,
      company,
      phone,
      email,
      inquiry,
      message,
      errorSnackbar,
      successSnackbar,
      status,
      isPhone,
    } = this.state;

    const StepBox = ({ icon: Icon, ...props }) => (
      <div className={classes.stepBox}>
        <Icon className={classes.stepBoxIcon} />

        <Typography className={classes.stepBoxText}>{props.text}</Typography>
      </div>
    );

    return (
      <div className={classes.root}>
        <Helmet>
          <title>F.A.Q | Gasa Tulen</title>
        </Helmet>

        <div className={classes.applySection}>
          <Typography className={classes.applySectionTitle}>
            How to apply?
          </Typography>
          <div className={classes.applySectionContent}>
            <StepBox
              icon={ApplyIcon}
              text="Submit your resume at hr.gasatulen@gmail.com"
            />
            <StepBox
              icon={ReviewIcon}
              text="HR and our team will review your resume"
            />
            <StepBox
              icon={InviteIcon}
              text="You will get an interview invitation"
            />
            <StepBox
              icon={SendIcon}
              text="Your interview result will be sent whenever it is ready"
            />
          </div>
        </div>
        {!isEmpty(status) && (
          <div className={classes.snackbarContainer}>
            {status === 200 ? (
              <Slide in={successSnackbar} direction="up">
                <div className={classes.successSnackbar}>
                  <Typography className={classes.snackbarMsg}>
                    {message}
                  </Typography>
                </div>
              </Slide>
            ) : (
              <Slide in={errorSnackbar} direction="up">
                <div className={classes.errorSnackbar}>
                  <Typography className={classes.snackbarMsg}>
                    {message}
                  </Typography>
                </div>
              </Slide>
            )}
          </div>
        )}
        <div className={classes.formContainer}>
          <img src={GTOffice} className={classes.formImg} />
          <div className={classes.form}>
            <Typography className={classes.formTitle}>
              Reach us online
            </Typography>
            <Typography className={classes.formDesc}>
              Gasa Tulen is committed to exceeding your needs. Questions,
              comments or special requests? We’d love to hear from you, so don’t
              hesitate in reaching out today.
            </Typography>
            <div className={classes.formContent}>
              <div className={classes.formChild}>
                <FormControl className={classes.textField}>
                  <InputLabel
                    className={classes.inputLabel}
                    classes={{
                      root: classes.MuiInputLabelRoot,
                    }}
                  >
                    Name
                  </InputLabel>
                  <OutlinedInput
                    value={name}
                    onChange={this.handleChange("name")}
                    placeholder="Your Name"
                  />
                </FormControl>
                <FormControl className={classes.textField}>
                  <InputLabel
                    classes={{
                      root: classes.MuiInputLabelRoot,
                    }}
                    className={classes.inputLabel}
                  >
                    Company
                  </InputLabel>
                  <OutlinedInput
                    value={company}
                    onChange={this.handleChange("company")}
                    placeholder="Company Name"
                  />
                </FormControl>
              </div>
              <div className={classes.formChild}>
                <FormControl className={classes.textField}>
                  <InputLabel
                    className={classes.inputLabel}
                    classes={{
                      root: classes.MuiInputLabelRoot,
                    }}
                  >
                    Phone
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment
                        disableTypography
                        //variant="filled"
                        position="start"
                      >
                        +60
                      </InputAdornment>
                    }
                    type="number"
                    value={phone}
                    onChange={this.handleChange("phone")}
                    placeholder="Phone number"
                  />
                </FormControl>
                <FormControl className={classes.textField}>
                  <InputLabel
                    className={classes.inputLabel}
                    classes={{
                      root: classes.MuiInputLabelRoot,
                    }}
                  >
                    Email
                  </InputLabel>
                  <OutlinedInput
                    value={email}
                    onChange={this.handleChange("email")}
                    placeholder="Your email"
                  />
                </FormControl>
              </div>
              <FormControl
                className={classes.textField}
                style={{ width: "100%" }}
              >
                <InputLabel
                  className={classes.inputLabel}
                  classes={{
                    root: classes.MuiInputLabelRoot,
                  }}
                >
                  Inquiry
                </InputLabel>
                <OutlinedInput
                  inputProps={{
                    maxLength: 250,
                  }}
                  multiline
                  rows={5}
                  value={inquiry}
                  onChange={this.handleChange("inquiry")}
                  //className={classes.textField}
                  placeholder="250 max characters"
                />
              </FormControl>
              <Button
                className={classes.formButton}
                variant="contained"
                color="secondary"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Gallery);
