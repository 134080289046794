import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Hidden,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import missionImage from "../images/missionImage.png";
import visionImage from "../images/visionImage.png";

import Footer from "../components/common/Footer";
import PageHeader from "../components/common/PageHeader";
import SectionTitle from "../components/common/SectionTitle";
import { Helmet } from "react-helmet";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  storySection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 0",
  },
  storyText: {
    margin: "1rem 0",
    color: "#888",
    fontSize: "1rem",
    letterSpacing: "1px",
    width: "85%",
    maxWidth: "1100px",
    textAlign: "justify",
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
      textAlign: "left",
    },
  },
  policySection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem 0",
  },
  pointContainer: {
    alignSelf: "center",
    maxWidth: "700px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
    },
  },
  pointTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    margin: "0.5rem 0",
  },
  pointText: {
    color: "#888",
    fontSize: "1rem",
    letterSpacing: "1px",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      lineHeight: "1.4rem",
      textAlign: "left",
    },
  },
  orgSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 0",
  },
  orgContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  person: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem 0",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "1.5rem 0",
    },
  },
  personName: {
    textTransform: "uppercase",
    fontSize: "1.2rem",
    letterSpacing: "2px",
    // fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      textAlign: "center",
      width: "85%",
    },
  },
  personPos: {
    fontSize: "1rem",
    color: "#888",
    letterSpacing: "1px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },

  missionVisionSection: {
    //height: "80vh",
    minHeight: "700px",
    width: "100%",
    padding: "3rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
  },
  missionVisionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "90%",
    },
  },
  missionVisionContent: {
    width: "40%",
    margin: "1rem 2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1.5rem 0",
    },
  },
  missionVisionTitle: {
    letterSpacing: "1px",
    //marginBottom: "0",
    fontSize: "4rem",
    fontWeight: "600",
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem",
    },
  },
  missionVisionDetail: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  missionVisionImageContainer: {
    maxHeight: "100%",
    maxWidth: "60%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "40%",
    },
  },
  missionVisionImage: {
    width: "100%",
    //maxHeight: "100%"
  },
  missionVisionPoint: {
    display: "flex",
    flexDirection: "row",
    margin: "0.5rem 0",
  },
  missionVisionPointContent: {
    fontSize: "1.3rem",
    //letterSpacing: "0.5px",
    color: "#666",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1 rem",
    },
  },
});

class About extends Component {
  state = {
    award: null,
  };

  handleAward = (value) => () => {
    if (value !== this.state.award) {
      this.setState({
        award: value,
      });
    } else {
      this.setState({
        award: null,
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { award } = this.state;

    const MissionVisionContent = (props) => (
      <div className={classes.missionVisionContent}>
        <Typography
          className={classes.missionVisionTitle}
          style={{ textAlign: props.inverted ? "right" : "left" }}
        >
          {props.title}
        </Typography>
        <div className={classes.missionVisionDetail}>{props.children}</div>
      </div>
    );

    const MissionVisionPoint = (props) => (
      <div
        className={classes.missionVisionPoint}
        style={{ justifyContent: props.inverted ? "flex-end" : "flex-start" }}
      >
        {!props.inverted && (
          <i
            className="far fa-dot-circle"
            style={{ marginRight: "1rem", marginTop: "8px" }}
          />
        )}
        <Typography
          className={classes.missionVisionPointContent}
          style={{
            textAlign: props.inverted ? "right" : "justify",
          }}
        >
          {props.children}
        </Typography>
        {props.inverted && (
          <i
            className="far fa-dot-circle"
            style={{ marginLeft: "1rem", marginTop: "8px" }}
          />
        )}
      </div>
    );

    const PointText = (props) => (
      <div className={classes.pointTextBox}>
        <i
          className="fas fa-check-circle"
          style={{
            color: "#555",
            marginRight: "1rem",
            marginTop: "0.25rem",
            fontSize: "1.1rem",
          }}
        />
        <Typography className={classes.pointText}>{props.children}</Typography>
      </div>
    );

    const Person = (props) => (
      <div className={classes.person}>
        <Typography className={classes.personName}>{props.name}</Typography>
        <Typography className={classes.personPos}>{props.pos}</Typography>
      </div>
    );

    return (
      <div className={classes.root}>
        <Helmet>
          <title>About us | Gasa Tulen</title>
        </Helmet>

        <div className={classes.missionVisionSection} id="dotSection">
          <div className={classes.missionVisionContainer}>
            <Hidden smDown>
              <div className={classes.missionVisionImageContainer}>
                <img
                  className={classes.missionVisionImage}
                  src={missionImage}
                  alt={missionImage}
                />
              </div>
            </Hidden>
            <MissionVisionContent title="Mission">
              <MissionVisionPoint>
                Adequate labor supply in accordance with market demand.
              </MissionVisionPoint>
              <MissionVisionPoint>
                To continuously upgrade and leverage on the company's technical,
                management capabilities, human resources, facilities, equipment,
                financial, and operational to meet future challenges and exceed
                expectations.
              </MissionVisionPoint>
              <MissionVisionPoint>
                Provide training to employees.
              </MissionVisionPoint>
              <MissionVisionPoint>
                To create a sustainable operation as a reliable oil and gas
                service provider.
              </MissionVisionPoint>
              <MissionVisionPoint>
                Providing health and safety awareness towards zero accidents.
              </MissionVisionPoint>
            </MissionVisionContent>
          </div>
        </div>
        <div className={classes.missionVisionSection} id="dotSection">
          <div className={classes.missionVisionContainer}>
            <MissionVisionContent title="Vision" inverted>
              <MissionVisionPoint inverted>
                Workforce proactive and skilled according to market needs.
              </MissionVisionPoint>
              <MissionVisionPoint inverted>
                Grow our staff's capabilities, creating a great working place
                and inspring them to be the best they can be.
              </MissionVisionPoint>
              <MissionVisionPoint inverted>
                Consistently strive to deliver cost effective, on time and
                comprehensive solutions to our clients utilizing the lastest
                technology in machinery and equipment ant to meet the industry
                standards for safety and competency.
              </MissionVisionPoint>
            </MissionVisionContent>
            <Hidden smDown>
              <div className={classes.missionVisionImageContainer}>
                <img
                  className={classes.missionVisionImage}
                  src={visionImage}
                  alt={visionImage}
                />
              </div>
            </Hidden>
          </div>
        </div>

        <div className={classes.policySection}>
          <SectionTitle title="hse policy" />
          <Typography className={classes.storyText}>
            The Health and Safety at work imposes a statutory duty on employers
            to ensure in so far as is reasonable practicable the health and
            safety of their employees whilst at work. This duty also extends to
            others who may be affected by that work. Employees also have a
            statutory duty to take care of themselves and others who may be
            affected by their acts and omissions. To enable these duties to be
            carried out, it is our intent to ensure that responsibilities for
            Health and Safety matters are effectively assigned, accepted and
            fulfilled at all levels within our organizational sturcture.
          </Typography>
          <Typography className={classes.storyText}>
            We will, so far as is reasonable practicable, ensure that :
          </Typography>
          <div className={classes.pointContainer}>
            <PointText>
              Adequate resources are provided to ensure that proper provision
              can be made for health and safety.
            </PointText>
            <PointText>
              System of works are provided and maintained that are safe anf
              without risks to health.
            </PointText>
            <PointText>
              All accidents, incidents, injuries spills and releases are
              preventable.
            </PointText>
            <PointText>
              All employees are provided with such information, instruction,
              training, and supervision as is necessary to secure their safety
              and health at work and the safety of others who may be affected by
              their actions.
            </PointText>
            <PointText>
              The place of work is safe and that there is safe access to and
              egress from the workplace.
            </PointText>
            <PointText>
              Health, safety, and environmental performance will be measured and
              continuously improved.
            </PointText>
          </div>
        </div>
        <div className={classes.orgSection}>
          <SectionTitle title="Meet our team" />
          <div className={classes.orgContent}>
            <Person
              name="Dato' Mohd Yusof bin Abu Bakar"
              pos="Managing Director"
            />

            <Person name="Datin Asmawati binti Ismail" pos="Director" />
            <Person name="Adli Azami bin Kori" pos="Technical Director" />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(About);
