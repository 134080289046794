import { GET_POSITION, GET_PLACES } from "../actions";

const initialState = {
  position: [],
  places: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_POSITION:
      return {
        ...state,
        position: action.payload
      };
    case GET_PLACES:
      return {
        ...state,
        places: action.payload
      };

    default:
      return state;
  }
}
